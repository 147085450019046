@font-face {
  font-family: TTCommons-Regular;
  src: url('./TTCommons-Regular.woff') format('woff'),
    url('./TTCommons-Regular.woff2') format('woff2');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: TTCommons-Medium;
  src: url('./TTCommons-Medium.woff') format('woff'),
    url('./TTCommons-Medium.woff') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: TTCommons-DemiBold;
  src: url('./TTCommons-DemiBold.woff') format('woff'),
    url('./TTCommons-DemiBold.woff') format('woff2');
  font-weight: 600;
  font-display: swap;
}
